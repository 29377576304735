/*Pages Common CSS*/

.container {
  max-width: 1200px;
}

main {
  min-height: 350px;
}

h5.header-tab {
  line-height: 36px;
  margin: 0;
}

.body-section {
  padding: 15px 0 10px;
  width: 100%;
  overflow: hidden;
  .form-check .form-check-input {
    margin: 6px 0 0 -19px !important;
    transform: scale(1.5);
  }
  .body-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $darkGray;
    font-size: 30px;
    color: $midnightBlue;
    width: 100%;
    padding: 0 0 10px;
    line-height: 42px;
    font-weight: 500;
    .back-btn {
      color: $midnightBlue;
      &:hover {
        color: $midnightBlue;
        text-decoration: none;
      }
    }
  }
  .more-search-link {
    text-decoration: underline;
    @include on-event {
      text-decoration: none;
    }
  }
  .filter-section {
    margin: 30px 0;
    .filter-head {
      font-size: 16px;
      font-weight: bold;
      color: $midnightBlue;
    }
    label {
      margin-bottom: 7px;
    }
  }
}

.table-bordered {
  border: 1px solid #aaa;
}

.search-step {
  margin: 20px 0 20px;
  @include mediaQuery('phone') {
    margin: 25px 0 10px;
  }
  & > ul {
    padding: 0;
    margin: auto;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 800px;
    position: relative;
    @include mediaQuery('tablet-portrait') {
      max-width: 90%;
    }
    @include mediaQuery('phone') {
      max-width: 80%;
    }
    &::after {
      content: '';
      height: 1px;
      background: $darkGray;
      position: absolute;
      left: 0;
      right: 0;
      z-index: -1;
      top: 50%;
      font-size: 14px;
    }
    li {
      a {
        height: 28px;
        width: 28px;
        background: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $darkGray;
        border-radius: 100%;
        text-decoration: none;
        position: relative;
        font-size: 14px;
        color: #ff9900;
        & > span {
          position: absolute;
          top: 100%;
          display: block;
          white-space: nowrap;
          margin-top: 6px;
          font-size: 14px;
          color: $text-color;
          @include mediaQuery('phone') {
            font-size: 14px;
            display: none;
          }
        }
      }
      &.active > a {
        background: #ff9900;
        border: 1px solid #ff9900;
        color: $white;
      }
    }
  }
}

.button-row {
  margin-top: 15px;
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  &:not(.no-border) {
    border-top: 1px solid $lightGray;
    [class*='col-'] {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .btn {
      margin: 0 0 10px 10px;
    }
  }
  @include mediaQuery('small') {
    flex-direction: column;
    align-items: stretch;
    .btn {
      margin-left: 0;
      margin-bottom: 10px;
      text-align: center;
    }
  }
}

.select-risk-section {
  overflow: hidden;
  .risk-info-box {
    border: 1px solid $lightGray;
  }
  .risk-list {
    li {
      &:not(:last-child) {
        border-bottom: 1px solid $lightGray;
      }
      a {
        display: inline-block;
        padding: 10px;
      }
    }
  }
}

.modal-dialog-scrollable .modal-body .modal-body {
  overflow-x: hidden;
  height: calc(100vh - 215px);
}

.delete-link {
  font-size: 14px;
  font-weight: normal;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  margin: 0;
  & > span {
    text-decoration: underline;
  }
  i {
    margin-right: 5px;
    display: inline-block;
  }
  @include on-event {
    text-decoration: none;
    span {
      text-decoration: none;
    }
  }
}

.add-claim-info {
  margin-top: 40px;
}

.modal-title {
  font-size: 20px;
}

#okta-sign-in.auth-container.main-container {
  margin-top: 20px;
  margin-bottom: 20px;
}

.modal-body .modal-header {
  padding-top: 0;
}

.modal-body .modal-footer {
  padding-bottom: 0;
}

// .modal {
//   overflow: auto;
//   .modal-body {
//     max-height: 70vh;
//     overflow: auto;
//     @include mediaQuery('phone') {
//       max-height: 55vh;
//       overflow: auto;
//     }
//   }
// }
.inner-page-content {
  padding: 40px 0;
}

.business-info {
  .business-info-label {
    margin-bottom: 0;
    .form-group {
      margin-bottom: 0;
    }
  }
  label {
    display: none;
  }
}

.coverageContainer .dataTables_wrapper .dataTables_filter {
  float: left !important;
  input {
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.2rem 0.375rem 0.375rem;
    border: 1px solid #ced4da;
    width: 240px;
    margin-left: 0;
    &[type='text'] {
      position: relative;
    }
  }
  &:after {
    font-family: 'FontAwesome';
    color: #999;
    position: relative;
    left: -20px;
    content: '\f002';
  }
}

.coverageContainer .table.dataTable.no-footer thead th:first-child {
  padding: 10px;
  text-align: center;
}

.filter-right .dataTables_wrapper .dataTables_filter {
  float: right !important;
  margin-right: 30px;
  input {
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.2rem 0.375rem 0.375rem;
    border: 1px solid #ced4da;
    width: 240px;
    margin-left: 0;
    &[type='text'] {
      position: relative;
    }
  }
  &:after {
    font-family: 'FontAwesome';
    color: #999;
    position: relative;
    left: -20px;
    content: '\f002';
  }
}

.filter-left .dataTables_wrapper .dataTables_filter {
  float: left !important;
  margin-right: 30px;
  input {
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.2rem 0.375rem 0.375rem;
    border: 1px solid #ced4da;
    width: 240px;
    margin-left: 0;
    &[type='text'] {
      position: relative;
    }
  }
  &:after {
    font-family: 'FontAwesome';
    color: #999;
    position: relative;
    left: -20px;
    content: '\f002';
  }
}

.filter-left {
  div.dt-buttons {
    position: relative;
    float: right;
  }
}

.info-icon-size {
  font-size: 20px;
}

.fa-info-circle {
  cursor: pointer;
}

.primary-color {
  color: #06c;
}

.buttons-pdf span,
.buttons-excel span {
  display: none;
}

.buttons-pdf,
.buttons-excel {
  border: none !important;
}

.buttons-pdf,
.buttons-excel {
  width: 24px;
  height: 24px;
  margin: 0 10px;
  cursor: pointer;
  background-color: #fff;
  background-image: inherit;
  border: none;
  box-shadow: none;
}

.buttons-excel {
  background: url(/assets/images/excel1.png) 0 0 no-repeat !important;
}

#availableCoverageGridContainer .dt-buttons {
  float: right;
}

div.dt-button-background {
  background: rgba(0, 0, 0, 0.7) !important;
}

.my-custom-class .tooltip-inner {
  background-color: #f4830b;
}

.my-custom-class .arrow::before {
  border-top-color: #f4830b;
}

.textToLabelClass {
  background: transparent !important;
  border: none !important;
  outline: none !important;
  padding: 0px 0px 0px 0px !important;
  max-width: 420px !important;
}

.textToLabelClass:focus {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #fff !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0 rgb(251, 250, 252) !important;
}

@media only screen and (max-width: 599px) {
  .button-top-row {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-top: 10px;
  }
}

.custom-radio-wrap {
  input {
    width: 20px;
    height: 20px;
  }
  label {
    font-size: 16px;
    line-height: 20px;
    vertical-align: bottom;
    margin-left: 4px;
  }
}

button.btn.btn-block {
  padding: 6px 16px 5px;
}

.btn-align-field {
  margin-top: 35px;
}
.formio-component-checkbox {
  margin-top: 42px;
}
.formio-component-checkbox .form-check-label span {
  margin-left: 5px;
}
.formio-choices.form-group {
  min-width: auto;
  margin-bottom: 0;
}
.formio-component h4 {
  margin-top: 10px;
}

.btn.disabled,
.btn-primary.disabled,
.btn-primary:disabled,
.btn-secondary.disabled,
.btn-secondary:disabled {
  cursor: not-allowed;
  pointer-events: none;
}

@media (min-width: 768px) {
  .col-md-20 {
    width: 210px;
    float: left;
    padding: 0 15px;
    max-width: 210px;
  }
  .col-md-80 {
    width: calc(100% - 210px);
    float: left;
    padding: 0 15px;
    flex: 0 0 80%;
  }
}

.custom-radio-wrap label {
  vertical-align: bottom;
}

.formio-error-wrapper {
  color: #333;
  background-color: transparent;
  border-color: #f5c6cb;
  padding: 0;
}
.formio-component-button {
  margin-top: 1.5rem;
}

.is-disabled .form-control {
  background: #e9ecef;
}
