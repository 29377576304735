/**
 * Basic typography style for copy text
 */
body {
  font: normal 15px / 1.4 $text-font-stack;
  line-height: 1.66667;
  color: $text-color;
  background-color: $white;
  font-weight: 400;
}

h1 {
  font-size: 42px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

h5,
h6 {
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  color: $midnightBlue;
}
