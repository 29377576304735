// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------
// @import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css';
//@import 'node_modules/@fortawesome/fontawesome-free-webfonts/css/fontawesome';
@each $fontName in $fontNames {
  @font-face {
    font-family: '#{$fontName}';
    src: url($font-file-path+$fontName+'.woff2') format('woff');
  }
}
