// -----------------------------------------------------------------------------
// This file contains all Sass variables.
// -----------------------------------------------------------------------------

/// Regular font family
$text-font-stack: 'tmhcc', 'Arial', sans-serif !default;

/// text color
$text-color: #333 !default;
$azureRadiance: #009ce5;
$scienceBlue: #0066cc;
$tahitiGold: #f4830b;
$cornflowerBlueapprox: #007bff;
$alto: #aaa;
$error: #dc3545;
$border: #ced4da;

/// Background color
$brand-color: #dc3545 !default;
$white: #fff;
$midnightBlue: #036;
$seagull: #85bdec;
$gray: #666;
$darkGray: #999;
$lightGray: #aaa;
$disable: #e9ecef;

/// Container's maximum width
$max-width: 1170px !default;

/// Breakpoints map
$breakpoints: (
  'small': 600px,
  'phone': 768px,
  'tablet-portrait': 1024px,
  'tablet-landscape': 1200px
) !default;

/// Relative or absolute URL where all assets are served from
$base-url: 'libs/assets/' !default;
// $filepath: $base-url + 'fonts/';
$fontNames: 'tmhcc-light', 'tmhcc', 'tmhcc-it', 'tmhcc-semi-bold', 'tmhcc-bold';
