// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
.upperCase {
  text-transform: uppercase;
}
.btn {
  font-size: 16px;
  color: $white;
  padding: 6px 30px 5px;
  // text-transform: uppercase;
  border-radius: 2px;
  font-weight: 700;

  &.btn-primary {
    background: $azureRadiance;
    border-color: $azureRadiance;

    @include on-event {
      background: darken($azureRadiance, 5%);
      border-color: darken($azureRadiance, 5%);
      text-decoration: none;
    }
  }

  &.btn-secondary {
    background: transparent;
    border: 1px solid $midnightBlue;
    color: $midnightBlue;

    @include on-event {
      background: $midnightBlue;
      color: $white;
      text-decoration: none;
    }
  }
  &.btn-sm {
    font-size: 14px;
    padding: 4px 10px;
  }
}
