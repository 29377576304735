// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
@charset "UTF-8";
.table {
  tr td,
  tr th {
    border: 1px solid $lightGray;
    white-space: nowrap;
    font-size: 15px;
    white-space: initial;
    &:first-child {
      border-left: 0 none;
    }
    &:first-child {
      border-right: 0 none;
    }
  }

  &.dataTable {
    margin-bottom: 0 !important;
    thead th {
      background: $scienceBlue;
      color: $white;
      position: relative;

      &.sorting:after,
      &.sorting:before,
      &.sorting_asc:after,
      &.sorting_asc:before,
      &.sorting_desc:after,
      &.sorting_desc:before {
        position: absolute;
        bottom: 0.9em;
        display: block;
        opacity: 0.3;
        top: 10px;
        right: 10px;
      }
      &.sorting {
        &:after {
          content: '\f0d7';
          font-family: FontAwesome;
          top: 13px;
        }
        &::before {
          content: '\f0de';
          font-family: FontAwesome;
        }
      }
      &.sorting_asc {
        &:after {
          content: '\f0d7';
          font-family: FontAwesome;
          opacity: 1;
          top: 13px;
        }
        &::before {
          content: '\f0de';
          font-family: FontAwesome;
        }
      }
      &.sorting_desc {
        &:after {
          content: '\f0d7';
          font-family: FontAwesome;
          top: 13px;
        }
        &::before {
          content: '\f0de';
          font-family: FontAwesome;
          opacity: 1;
        }
      }
    }
    thead th,
    tbody td {
      padding: 12px 10px;
      font-size: 15px;
      font-weight: 400;
    }

    & > tbody > tr.child span.dtr-title {
      min-width: 120px;
      text-transform: uppercase;
    }

    &.no-footer {
      border: 0 none;

      thead th {
        border-bottom: 1px solid transparent;
        border-top: 1px solid transparent;
        text-transform: uppercase;
        padding: 10px 28px 10px 10px;
        border-right: 0 none;
      }

      tbody td {
        border-bottom: 1px solid $lightGray;
        padding: 14px 10px;
        border-bottom: 0 none;
        border-right: 0 none;

        > div {
          max-width: 150px;
        }
      }
    }
    &.dtr-inline.collapsed > tbody > tr[role='row'] > td {
      &:first-child {
        padding-left: 40px;

        &:before {
          border-radius: 0;
          box-shadow: none;
          border: 0 none;
          background: $azureRadiance;
          top: 14px;
          left: 9px;
          height: 18px;
          width: 18px;
          line-height: 18px;
        }
      }
    }
    &.dtr-inline.collapsed > tbody > tr.parent > td:first-child:before {
      background: $gray;
    }

    .form-control {
      width: 200px;
    }

    textarea.form-control {
      max-width: 200px;
    }

    .input-group {
      max-width: 160px;
    }
  }
}
.dataTables_wrapper {
  .dataTables_paginate {
    .paginate_button {
      color: $gray !important;
      border: 1px solid transparent;
      border-radius: 0;
      padding: 4px 14px;

      &.disabled,
      &.disabled:hover,
      &.disabled:active {
        cursor: default;
        color: $gray !important;
        border: 1px solid transparent;
        background: transparent;
        box-shadow: none;
      }

      &.current {
        color: $white !important;
        border: 1px solid $azureRadiance;
        background: $azureRadiance;
      }

      &:hover,
      &.current:hover {
        color: $white !important;
        border: 1px solid $azureRadiance;
        background: $azureRadiance;
      }
    }
  }
  .dataTables_scroll {
    border: 1px solid $alto;
  }
  .dataTables_scrollBody {
    border: 0 none !important;

    table > tbody > tr > td {
      vertical-align: top !important;
    }
  }
  .dataTables_paginate {
    padding: 15px 0 0 0;
  }
}
pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
  max-width: 150px;
  overflow: visible;
  white-space: normal;
  word-break: break-all;
}

div.dt-button-collection {
  max-height: 700px;
  overflow-y: auto;
}
.is-hidden {
  display: none;
}
