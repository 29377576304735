// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
.nav-tabs {
  .nav-link {
    border: 0 none;
    border-bottom: 4px solid transparent;
    flex: 0 1 auto;
    font-size: 16px;
    color: $text-color;

    @include mediaQuery('tablet-landscape') {
      font-size: 14px;
    }

    @include mediaQuery('phone') {
      padding: 6px 0;
      margin-right: 10px;
      font-size: 14px;
    }

    @include on-event {
      color: #333;
      background-color: transparent;
      border-color: transparent transparent $white;
      border-bottom: $tahitiGold 4px solid !important;
      text-decoration: none;
    }
  }
}
