// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

// Extended Property
%list-setting {
  padding: 0;
  margin: 0;
  list-style: none;
}

.footer {
  background: $midnightBlue;
  padding: 85px 0 45px;

  @include mediaQuery('phone') {
    padding: 20px 0 0;
  }

  .footer-nav {
    @extend %list-setting;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    @include mediaQuery('phone') {
      flex-direction: column;
      align-items: stretch;
      text-align: center;
      flex-direction: column;
    }

    li {
      &:not(:last-child) {
        margin-right: 25px;
      }

      @include mediaQuery('phone') {
        margin: 0 !important;
        border-bottom: 1px solid lighten($midnightBlue, 10%);
      }

      a {
        display: inline-block;
        border-bottom: 0;
        text-transform: uppercase;
        color: $white;
        font-weight: 700;

        @include mediaQuery('phone') {
          display: block;
          text-transform: uppercase;
          line-height: 50px;
        }
      }
    }
  }

  .footer-social {
    @extend %list-setting;
    margin: 50px 0 115px;
    display: flex;
    width: 100%;
    justify-content: center;

    @include mediaQuery('phone') {
      margin: 30px 0;
    }
    .social-icon {
      &:not(:last-child) {
        margin-right: 12px;
      }
      a {
        display: flex;
        position: relative;
        width: 36px;
        height: 36px;
        overflow: hidden;
        color: $seagull;
        border: 2px solid $seagull;
        border-radius: 100%;
        align-items: center;
        justify-content: center;

        @include on-event {
          color: darken($seagull, 20%);
          border: 2px solid darken($seagull, 20%);
          text-decoration: none;
        }
      }
    }
  }
}

.footer-bottom {
  @include mediaQuery('phone') {
    margin: 0;
  }

  .legel-nav {
    @extend %list-setting;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    @include mediaQuery('tablet-landscape') {
      justify-content: center;
    }

    li {
      &:not(:last-child) {
        margin-right: 10px;
      }

      a {
        display: inline-block;
        font-size: 13px;
        color: $seagull;

        @include mediaQuery('tablet-landscape') {
          font-size: 12px;
        }

        @include mediaQuery('phone') {
          font-size: 14px;
        }
      }
    }
  }
  .copyright {
    color: $white;
    font-size: 13px;
    padding: 0;
    margin: 0;

    @include mediaQuery('tablet-portrait') {
      text-align: center !important;
      font-size: 12px;
    }

    @include mediaQuery('phone') {
      margin-top: 10px;
      font-size: 14px;
    }

    a {
      color: $seagull;
    }
  }
}
