// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

body {
  position: relative;
}
/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

/**
 * Basic styles for links
 */
a {
  color: $cornflowerBlueapprox;
  text-decoration: none;

  @include on-event {
    color: darken($cornflowerBlueapprox, 10%);
    text-decoration: underline;
  }
}

table,
table tr th,
table tr td {
  font-size: 14px;
}

.table-responsive {
  border: 1px solid $lightGray;
}
.table-responsive > table {
  margin: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* ******************************DT Button********************************** */
.customBtnGrid .dt-buttons {
  position: relative;
  // float: right !important;
}

.customBtnGrid .dt-buttons > .dt-button > span {
  color: #007bff;
}

.customBtnGrid button.dt-button {
  border: none;
  background-color: #fff;
  background-image: none;
}

.customBtnGrid button.dt-button:hover:not(.disabled),
.customBtnGrid button.dt-button:active:not(.disabled),
.customBtnGrid button.dt-button:active:not(.disabled):hover:not(.disabled) {
  border: none;
  background-color: #fff;
  background-image: none;
  box-shadow: none;
}

.customBtnGrid div.dt-button-collection button.dt-button.active:not(.disabled),
.customBtnGrid button.dt-button.active:not(.disabled):hover:not(.disabled) {
  color: #fff;
  background-color: #009ce5;
  border-color: #009ce5;
  background-image: none;
  box-shadow: none;
}

.customBtnGrid .dt-buttons > .dt-button > span::after {
  content: '';
  width: 0px;
  height: 0px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #007bff;
  vertical-align: middle;
  position: absolute;
  bottom: 36%;
  right: 0%;
}

.customBtnGrid .dropdown-item {
  width: 2% !important;
}
/* ******************************End DT Button********************************** */
